export const IconAllo = ({ className = '' }) => (
  <svg viewBox="0 0 96 96" stroke="currentColor" fill="currentColor" className={className}>
    <path d="M86.1,39.9c2.7,35-36.8,62.2-42.4,54.9c-2.6-3.4-0.3-5.7,2.6-8.5c3.4-3.3,7.5-7.4,5.8-14.9c-1.6-6.7-8.4-8.6-16.1-10.8 c-8.3-2.4-17.7-5-22.6-14.4C4,28.3,13.6,6.5,35.7,1.4C57.8-3.8,83.4,4.9,86.1,39.9z" />
  </svg>
)

export const IconLora = ({ className = '' }) => (
  <svg viewBox="0 0 93 36" stroke="currentColor" fill="currentColor" className={className}>
    <path d="m.0400001 35.2v-34.400001h10.6799999v4.920001h-5.36v24.56h5.36v4.92zm92.8308999-34.400001v34.400001h-10.68v-4.92h5.36v-24.56h-5.36v-4.920001z"></path>
    <path d="m13.1631 30v-28h5.64v28zm19.6103.24c-6.56 0-10.84-4.2-10.84-10.52s4.28-10.52 10.84-10.52 10.84 4.2 10.84 10.52c0 6.36-4.28 10.52-10.84 10.52zm0-4.6c3.16 0 5.12-2.44 5.12-5.92s-1.96-5.92-5.12-5.92-5.08 2.44-5.08 5.92 1.92 5.92 5.08 5.92zm25.5044-16.2h.96v5.12h-2.24c-3.36 0-4.64 2.24-4.64 5.36v10.08h-5.64v-20.56h5.12l.52 3.08c1.12-1.84 2.72-3.08 5.92-3.08zm22.5713 15.8h.64v4.76h-2.88c-2.92 0-3.84-1.4-3.8-3.36-1.44 2.28-3.52 3.6-6.72 3.6-4.44 0-7.64-2.12-7.64-6.04 0-4.36 3.28-6.84 9.44-6.84h4.08v-1c0-1.84-1.32-3.04-3.68-3.04-2.2 0-3.68 1-3.92 2.52h-5.44c.4-4 4.08-6.64 9.52-6.64 5.76 0 9.08 2.56 9.08 7.48v7.28c0 1.08.44 1.28 1.32 1.28zm-6.88-3.72v-.4h-4.12c-2.36 0-3.72.96-3.72 2.68 0 1.44 1.16 2.36 3.04 2.36 2.96 0 4.76-1.84 4.8-4.64z"></path>
  </svg>
)

export const IconPeraExplorer = ({ className = '' }) => (
  <svg viewBox="0 0 86 96" stroke="currentColor" fill="currentColor" className={className}>
    <path d="M48.5471 14.107C50.5942 22.5886 49.9022 30.0494 47.0014 30.771C44.1007 31.4926 40.0896 25.202 38.0425 16.7203C35.9953 8.23873 36.6874 0.778021 39.5881 0.056374C42.4889 -0.665273 46.4999 5.62542 48.5471 14.107Z" />
    <path d="M82.3504 21.3992C77.8168 16.5942 68.7972 17.8966 62.2045 24.3081C55.6118 30.7196 53.9426 39.8123 58.4762 44.6173C63.0098 49.4222 72.0294 48.1199 78.6221 41.7084C85.2148 35.2969 86.884 26.2041 82.3504 21.3992Z" />
    <path d="M46.2926 94.9747C49.1934 94.253 49.7835 86.3702 47.6107 77.368C45.4379 68.3657 41.325 61.653 38.4242 62.3746C35.5235 63.0963 34.9333 70.9791 37.1061 79.9813C39.2789 88.9836 43.3918 95.6963 46.2926 94.9747Z" />
    <path d="M16.7223 25.7982C25.0912 28.2661 31.2064 32.5958 30.3809 35.4687C29.5555 38.3417 22.1021 38.67 13.7332 36.2021C5.36438 33.7341 -0.750778 29.4045 0.0746392 26.5315C0.900056 23.6586 8.35349 23.3302 16.7223 25.7982Z" />
    <path d="M71.0398 58.2396C79.9223 60.859 86.4539 65.3115 85.6285 68.1844C84.8031 71.0574 76.9332 71.2629 68.0507 68.6435C59.1681 66.024 52.6365 61.5716 53.4619 58.6986C54.2873 55.8257 62.1572 55.6201 71.0398 58.2396Z" />
    <path d="M26.1392 52.2116C24.0639 50.0603 17.2567 53.1913 10.935 59.205C4.61326 65.2187 1.17089 71.8377 3.24624 73.989C5.32159 76.1403 12.1288 73.0093 18.4505 66.9956C24.7722 60.9819 28.2146 54.3629 26.1392 52.2116Z" />
  </svg>
)
