/* eslint-disable @next/next/no-img-element */
import { NfdRecord } from 'api/api-client'
import { getSaleBannerURL } from 'helpers/saleBanner'

interface PurchaseLayoutProps {
  nfd: NfdRecord
  children: React.ReactNode
}

export default function PurchaseLayout({ nfd, children }: PurchaseLayoutProps) {
  return (
    <div className="bg-gray-100 flex-1 dark:bg-gray-900">
      <div className="relative bg-gray-100 pb-32 overflow-hidden dark:bg-gray-900">
        <div
          aria-hidden="true"
          className="absolute inset-0 sm:left-1/2 sm:transform sm:-translate-x-1/2 w-[200%] sm:w-full overflow-hidden dark:bg-gray-950"
        >
          <div className="relative flex justify-center">
            <img
              className="w-full h-full object-center object-cover flex-shrink-0 dark:opacity-0"
              src={getSaleBannerURL(nfd)}
              alt=""
            />
          </div>
        </div>
        <div className="relative py-4 sm:py-6" />
      </div>
      <main className="relative -mt-32">
        <div className="max-w-5xl mx-auto px-4 pb-6 sm:px-6 lg:pb-16 lg:px-8">
          <div className="bg-white rounded-lg shadow pb-3 dark:bg-gray-850">
            <div className="py-6 sm:py-8 md:py-12 px-4 sm:px-6 lg:px-8">{children}</div>
          </div>
        </div>
      </main>
    </div>
  )
}
