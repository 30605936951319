import { Fragment, useMemo } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { isValidAddress } from 'algosdk'
import useSearch, { getByQuery } from 'api/hooks/v2/useSearch'
import useDebounce from 'hooks/useDebounce'
import { classNames, isValidName, truncateAddress } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

interface LookupInputProps {
  value: string
  onChange: (string: string) => void
  exclude?: string | string[]
  placeholder?: string
  suggestionLimit?: number
}

export default function LookupInput({
  value,
  onChange,
  exclude,
  placeholder,
  suggestionLimit
}: LookupInputProps) {
  const debouncedQuery = useDebounce(value, 500)

  const sanitizeQuery = (query: string) => {
    return query
      .replace(/[^a-zA-Z0-9.]/g, '')
      .slice(0, 32)
      .toLowerCase()
  }

  const sanitizedQuery = useMemo(() => {
    return sanitizeQuery(debouncedQuery)
  }, [debouncedQuery])

  const enableQuery = useMemo(() => {
    return sanitizedQuery.length > 0 && debouncedQuery === value
  }, [debouncedQuery, value, sanitizedQuery])

  const {
    data = [],
    isLoading,
    error
  } = useSearch({
    params: {
      prefix: sanitizedQuery,
      view: 'full',
      sort: 'nameAsc'
    },
    options: {
      enabled: enableQuery
    }
  })

  const showOptions = enableQuery && !isLoading && !isValidAddress(value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const fetchExactMatch = async (query: string) => {
    const results = await getByQuery({
      prefix: sanitizeQuery(query)
    })

    return results[0]
  }

  const handleBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (!isValidName(inputValue)) {
      return
    }

    try {
      const result =
        data?.find((nfd) => nfd.name === inputValue) || (await fetchExactMatch(inputValue))

      const resultAddress = result.depositAccount

      if (!resultAddress) {
        return
      }

      if (exclude) {
        const isExcluded = Array.isArray(exclude)
          ? exclude.includes(resultAddress)
          : resultAddress === exclude

        if (isExcluded) {
          return
        }
      }

      onChange(resultAddress)
    } catch (err) {
      console.error(err)
    }
  }

  const renderAddress = (address: string | undefined, className = '') => {
    if (!address) {
      return null
    }

    const addr = truncateAddress(address, { array: true })

    return (
      <span
        className={classNames('text-sm text-gray-400 font-mono ml-3 dark:text-gray-500', className)}
      >
        {addr[0]}&hellip;{addr[1]}
      </span>
    )
  }

  const renderOptions = () => {
    const filterData = (result: NfdRecord) => {
      const resultAddress = result.depositAccount

      if (!resultAddress) {
        return false
      }

      if (exclude) {
        if (Array.isArray(exclude)) {
          return !exclude.includes(resultAddress)
        }

        return resultAddress !== exclude
      }

      return true
    }

    let suggestions: NfdRecord[]

    if (suggestionLimit) {
      suggestions = data?.filter(filterData).slice(0, suggestionLimit) || []
    } else {
      suggestions = data?.filter(filterData) || []
    }

    if (error) {
      return (
        <div className="cursor-default select-none relative py-2 px-4 text-gray-500">
          <span className="text-red-500">Error:</span> {error.message}
        </div>
      )
    }

    if (suggestions.length === 0) {
      return (
        <div className="cursor-default select-none relative py-2 px-4 text-gray-500">
          No matches found, try a different name
        </div>
      )
    }

    return suggestions.map((suggestion) => (
      <Combobox.Option
        key={suggestion.name}
        className={({ active }) =>
          `cursor-default select-none py-2 px-4 text-gray-900 truncate dark:text-gray-500 ${
            active || suggestion.name === value
              ? 'bg-gray-50 dark:bg-gray-900/75'
              : 'bg-white dark:bg-gray-800'
          }`
        }
        value={suggestion.depositAccount}
      >
        <span className="dark:text-gray-200">{suggestion.name}</span>
        {suggestion.depositAccount && renderAddress(suggestion.depositAccount)}
      </Combobox.Option>
    ))
  }

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative mt-1">
        <div className="relative w-full text-left rounded-md cursor-default focus:outline-none sm:text-sm">
          <Combobox.Input
            className="w-full border rounded-md shadow-sm border-gray-300 focus:ring-brand-500 focus:border-brand-500 py-2 pl-3 text-sm leading-5 text-gray-900 dark:bg-gray-750/60 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:placeholder-gray-400 dark:caret-gray-500"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="new-password"
            spellCheck="false"
            placeholder={placeholder}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {showOptions ? (
            <Combobox.Options className="absolute w-full z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40">
              {renderOptions()}
            </Combobox.Options>
          ) : (
            <div />
          )}
        </Transition>
      </div>
    </Combobox>
  )
}
