import { nfdOffer, type OfferRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type SaleParams = {
  name: string
  body: OfferRequestBody
}

export function usePostSale(options: MutationOptions<SaleParams> = {}) {
  return usePostTransaction<SaleParams>({
    mutationFn: ({ name, body }) => nfdOffer(name, body),
    ...options
  })
}
