import { TREASURY_ACCT } from './constants'
import { NfdRecord } from 'api/api-client'
import { WithRequiredProperty } from 'types/utility'

export const treasuryAcct: WithRequiredProperty<NfdRecord, 'caAlgo'> = {
  name: 'nfdomains.algo',
  properties: {
    userDefined: {
      avatar: 'https://images.nf.domains/avatar/d64e6b4f-77ce-4e30-8d22-64caf5f762fd'
    }
  },
  caAlgo: [TREASURY_ACCT]
}
