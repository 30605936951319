import { classNames } from 'helpers/utilities'

interface CalloutProps {
  theme?: 'green' | 'blue' | 'amber' | 'red'
  title?: string
  message?: string
  className?: string
  children?: React.ReactNode
}

const themes = {
  green: {
    bgColor: 'bg-green-50',
    accentColor: 'border-green-500'
  },
  blue: {
    bgColor: 'bg-blue-50',
    accentColor: 'border-blue-500'
  },
  amber: {
    bgColor: 'bg-amber-50',
    accentColor: 'border-amber-500'
  },
  red: {
    bgColor: 'bg-red-50',
    accentColor: 'border-red-500'
  }
}

export default function Callout({
  theme = 'blue',
  title,
  message,
  className = '',
  children
}: CalloutProps) {
  const { bgColor, accentColor } = themes[theme]

  return (
    <div className={classNames('rounded-md overflow-hidden', className)}>
      <div
        className={classNames(
          'p-4 border-l-4 dark:bg-gray-300/5 dark:highlight',
          bgColor,
          accentColor
        )}
      >
        <div className="ml-3">
          {title && (
            <h3 className="text-sm font-medium text-gray-900 dark:text-gray-300">{title}</h3>
          )}
          {message && (
            <p className="mt-2 text-sm text-gray-700 first-letter:capitalize dark:text-gray-400">
              {message}
            </p>
          )}
          {children && children}
        </div>
      </div>
    </div>
  )
}
