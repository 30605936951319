import { useMemo } from 'react'
import useAnalytics from 'api/hooks/useAnalytics'
import useAddresses from 'api/hooks/useAddresses'
import { useExplorerStore } from 'store/index'

export default function useSalesHistory(name: string) {
  const { data, isInitialLoading, error } = useAnalytics({
    params: {
      name,
      event: ['minted', 'sold'],
      requireBuyer: true,
      limit: 20
    }
  })

  const users = useMemo(() => {
    return data?.results
      ? data.results.reduce<string[]>((acc, record) => {
          const user = record.data?.buyer || record.data?.seller

          if (user && !acc.includes(user)) {
            acc.push(user)
          }

          return acc
        }, [])
      : []
  }, [data?.results])

  const usersWithThumbnails = useAddresses({
    params: {
      address: users,
      view: 'thumbnail'
    },
    options: {
      enabled: !!users && users.length > 0
    }
  })

  const selectedExplorer = useExplorerStore((state) => state.selectedExplorer)
  const lookupByGroupId = useExplorerStore((state) => state.lookupByGroupId)

  return {
    records: data?.results,
    isLoading: isInitialLoading,
    error,
    users,
    usersWithThumbnails,
    selectedExplorer,
    lookupByGroupId
  }
}
