export default function OffersHistorySkeleton() {
  return (
    <div className="animate-pulse">
      <div className="inline-block min-w-full align-middle">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-100 dark:bg-gray-900/75">
            <tr>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-gray-700/40 bg-gray-100 dark:bg-gray-900/75 pl-4 pr-3 py-5 sm:pl-6"
              >
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[48px]" />
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-gray-700/40 bg-gray-100 dark:bg-gray-900/75 px-3 py-5"
              >
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[48px]" />
              </th>
              <th
                scope="col"
                className="border-b border-gray-300 dark:border-gray-700/40 bg-gray-100 dark:bg-gray-900/75 pl-3 pr-4 py-5 sm:pr-6"
              >
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[32px] xl:max-w-[96px]" />
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-850">
            <tr>
              <td className="pl-4 pr-3 py-7 sm:pl-6 w-[33%]">
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[80px]" />
              </td>
              <td className="px-3 py-7">
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[120px]" />
              </td>
              <td className="pl-3 pr-4 py-7 w-16 sm:pr-6 xl:w-56">
                <div className="h-3 bg-gray-300 dark:bg-gray-750 rounded-full max-w-[32px] xl:max-w-[200px]" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
