import Link from 'next/link'
import { HiOutlineDocumentText, HiOutlineExclamationCircle } from 'react-icons/hi'
import ButtonLink from 'components/ButtonLink'
import type { NfdRecord } from 'api/api-client'

interface UpgradeRequiredProps {
  nfd: NfdRecord
  isOwner?: boolean
  compact?: boolean
  action?: React.ReactNode
}

export default function UpgradeRequired({
  nfd,
  isOwner = false,
  compact = false,
  action = <>use this&nbsp;feature</>
}: UpgradeRequiredProps) {
  if (compact) {
    return (
      <div className="mt-8 xl:mt-0 py-3 flex items-center">
        <div className="w-12 mr-3">
          <div className="flex items-center justify-center w-12 h-12 text-brand-400 bg-brand-100 rounded-full dark:bg-gray-300/10">
            <HiOutlineExclamationCircle className="w-9 h-9" />
          </div>
        </div>

        <div>
          <h1 className="text-lg font-medium text-gray-900 dark:text-gray-300">Upgrade Required</h1>
          <p className="mt-1 text-sm text-gray-500">
            {isOwner ? (
              <>
                Please{' '}
                <Link
                  href={`/manage/${nfd.name}?view=contract`}
                  className="text-gray-700 font-semibold hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-600 dark:font-medium"
                >
                  upgrade
                </Link>{' '}
                your smart contract to {action}
              </>
            ) : (
              <>The owner must upgrade smart contract to {action}</>
            )}
          </p>
        </div>
      </div>
    )
  }

  const renderMessage = () => {
    if (isOwner) {
      return (
        <>
          <p className="mt-2 text-sm text-center text-gray-500 sm:text-base">
            Please upgrade your smart contract to {action}.
          </p>
          <div className="mt-8 text-center">
            <ButtonLink
              variant="primary"
              icon={HiOutlineDocumentText}
              href={`/manage/${nfd.name}?view=contract`}
            >
              Upgrade Contract
            </ButtonLink>
          </div>
        </>
      )
    }

    return (
      <p className="mt-2 text-sm text-center text-gray-500">
        The owner must upgrade the smart contract to {action}.
      </p>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-10 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center w-16 h-16 text-brand-400 bg-brand-100 rounded-full dark:bg-gray-300/10 dark:text-brand-400">
        <HiOutlineExclamationCircle className="w-12 h-12" />
      </div>
      <h1 className="mt-3 text-xl font-medium text-center text-gray-900 dark:text-gray-100">
        Upgrade Required
      </h1>
      {renderMessage()}
    </div>
  )
}
