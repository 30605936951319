import Link from 'next/link'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { BiLinkExternal, BiWallet } from 'react-icons/bi'
import Alert from 'components/Alert'
import AlgoPrice from 'components/AlgoPrice'
import SalesHistorySkeleton from './SalesHistory.skeleton'
import UserThumbnail from 'components/UserThumbnail'
import { classNames, truncateAddress } from 'helpers/utilities'
import useSalesHistory from './SalesHistory.hooks'
import { treasuryAcct } from 'src/data/nfd'

dayjs.extend(localizedFormat)

type SalesHistoryProps = {
  name: string
  profileTab?: boolean
}

export default function SalesHistory({ name, profileTab = false }: SalesHistoryProps) {
  const { records, isLoading, error, usersWithThumbnails, selectedExplorer, lookupByGroupId } =
    useSalesHistory(name)

  const renderAddress = (address: string | undefined, className = '') => {
    if (!address) {
      return <span className="mt-1 text-sm text-gray-700 dark:text-gray-500">N/A</span>
    }

    // show nfdomains.algo for mainnet treasury acct
    if (address === treasuryAcct.caAlgo[0]) {
      return <UserThumbnail nfd={treasuryAcct} className="text-gray-900 dark:text-gray-300" />
    }

    const nfd = usersWithThumbnails.data?.find((user) =>
      user.caAlgo?.some((addr) => addr === address)
    )

    if (nfd) {
      return <UserThumbnail nfd={nfd} className="text-gray-900 dark:text-gray-300" />
    }

    const addr = truncateAddress(address, { array: true })

    return (
      <Link
        href={`/address/${address}`}
        className={classNames(
          'inline-flex items-center text-sm text-gray-900 font-mono dark:text-gray-400 dark:hover:text-brand-500',
          className
        )}
      >
        <BiWallet className="flex-shrink-0 mr-1.5 h-5 w-5 my-1.5" aria-hidden="true" />
        {addr[0]}&hellip;{addr[1]}
      </Link>
    )
  }

  const renderContent = () => {
    if (isLoading || usersWithThumbnails.isInitialLoading) {
      return <SalesHistorySkeleton />
    }

    if (error) {
      return (
        <div className={classNames(profileTab ? 'px-4 py-5 sm:px-6' : '')}>
          <Alert type="error" title={`Error fetching sales history`} error={error} />
        </div>
      )
    }

    if (!records || records.length === 0) {
      return (
        <div className={classNames(profileTab ? 'px-4 py-5 sm:px-6' : '')}>
          <p className="text-sm text-gray-500">No sales history yet.</p>
        </div>
      )
    }

    return (
      <div
        className={classNames(
          profileTab ? '' : 'border-t border-gray-100 dark:border-transparent',
          'inline-block min-w-full align-middle'
        )}
      >
        <div className="shadow-sm ring-1 ring-black ring-opacity-5 overflow-auto max-h-[27rem] landscape:max-h-72 dark:ring-0">
          <table
            key={selectedExplorer}
            className="min-w-full border-separate"
            style={{ borderSpacing: 0 }}
          >
            <thead className="relative">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:bg-[#151a28] dark:text-gray-500 dark:border-transparent"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:bg-[#151a28] dark:text-gray-500 dark:border-transparent"
                >
                  Buyer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 bg-gray-100 border-b border-gray-300 pl-3 pr-4 sm:pr-6 py-3.5 text-center text-sm font-semibold text-gray-900 xl:text-left dark:bg-[#151a28] dark:text-gray-500 dark:border-transparent"
                >
                  <span className="xl:hidden">Txn</span>
                  <span className="hidden xl:inline">Transaction</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-850">
              {records?.map((record, i) => (
                <tr key={`sales-record-${i}`}>
                  <td
                    className={classNames(
                      i !== records.length - 1
                        ? 'border-b border-gray-200 dark:border-gray-700/40'
                        : '',
                      'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 dark:text-gray-400'
                    )}
                  >
                    <AlgoPrice price={record.price} className="xl:hidden" />
                    <AlgoPrice price={record.price} className="hidden xl:inline" />
                  </td>
                  <td
                    className={classNames(
                      i !== records.length - 1
                        ? 'border-b border-gray-200 dark:border-gray-700/40'
                        : '',
                      'whitespace-nowrap px-3 py-4 text-sm'
                    )}
                  >
                    {renderAddress(record.data?.buyer)}
                  </td>
                  <td
                    className={classNames(
                      i !== records.length - 1
                        ? 'border-b border-gray-200 dark:border-gray-700/40'
                        : '',
                      'whitespace-nowrap py-4 pl-3 pr-4 w-16 text-center text-sm font-medium sm:pr-6 xl:w-56 xl:text-left'
                    )}
                  >
                    {record.data?.groupID ? (
                      <a
                        href={lookupByGroupId(record.data.groupID)}
                        className="inline-flex items-center text-gray-500 hover:text-gray-900 font-medium outline-brand-500 group dark:hover:text-gray-400"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="hidden xl:inline">
                          {dayjs(record.timestamp).format('lll')}
                        </span>
                        <BiLinkExternal className="m-2 xl:m-0 xl:ml-3 w-5 h-5 text-gray-400 group-hover:text-brand-500 dark:text-gray-600 dark:hover:text-brand-500" />
                      </a>
                    ) : (
                      <span className="hidden xl:inline">
                        {dayjs(record.timestamp).format('lll')}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  if (profileTab) {
    return renderContent()
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Sales History
      </h3>
      <div className="py-2">{renderContent()}</div>
    </div>
  )
}
