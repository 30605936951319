import type { NfdRecord } from 'api/api-client'

export const getSaleBannerURL = (nfd: NfdRecord): string => {
  const isCurated = nfd.category === 'curated'

  if (isCurated) {
    return `/img/nfd-brand-bg_gold.jpg`
  }

  return `/img/nfd-brand-bg_gray.jpg`
}
