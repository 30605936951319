import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HiOutlineExclamation } from 'react-icons/hi'
import { confirmable, createConfirmation } from 'react-confirm'

interface ConfirmProps {
  cancelText?: string
  confirmText?: string
  title?: string
  confirmation: string | JSX.Element | JSX.Element[]
  proceed: (value: boolean) => void
  show: boolean
  link?: {
    href: string
    label: string
    target?: '_blank' | '_self' | '_parent' | '_top' | string
  }
}

function ConfirmModal({
  show,
  proceed,
  confirmation,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  title = 'Are you sure?',
  link
}: ConfirmProps) {
  const cancelButtonRef = useRef(null)

  const renderConfirmation = () => {
    if (typeof confirmation === 'string') {
      return (
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {confirmation}
          {link ? (
            <a
              className="font-medium text-gray-700 underline hover:text-gray-900 dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
              href={link.href}
              target={link.target}
              rel={link.target ? 'noopener noreferrer' : ''}
            >
              {link.label}
            </a>
          ) : null}
        </p>
      )
    }

    return confirmation
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[60] inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => proceed(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity dark:bg-black/75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full dark:bg-gray-900">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-900">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-gray-300/10">
                    <HiOutlineExclamation
                      className="h-6 w-6 text-brand-600 dark:text-brand-300"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="my-2">{renderConfirmation()}</div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-950">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-600 text-base font-medium text-white hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 sm:ml-3 sm:w-auto sm:text-sm dark:bg-brand-600 dark:hover:bg-brand-500 dark:focus:ring-offset-gray-900 dark:ring-gray-100 dark:disabled:opacity-25 dark:disabled:bg-brand-600"
                  onClick={() => proceed(true)}
                >
                  {confirmText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-100 dark:disabled:bg-gray-750"
                  onClick={() => proceed(false)}
                  ref={cancelButtonRef}
                >
                  {cancelText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default createConfirmation(confirmable(ConfirmModal))
