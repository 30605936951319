import { useMemo } from 'react'
import useAnalytics from 'api/hooks/useAnalytics'
import useAddresses from 'api/hooks/useAddresses'
// import useRelativeTime from 'hooks/useRelativeTime'

export default function useOffersHistory(name: string) {
  // useRelativeTime({
  //   s: 'just now',
  //   m: 'a minute ago',
  //   mm: '%dm ago',
  //   h: '1h ago',
  //   hh: '%dh ago',
  //   d: '1d ago',
  //   dd: '%dd ago',
  //   M: '1mo ago',
  //   MM: '%dmo ago',
  //   y: '1y ago',
  //   yy: '%dy ago'
  // })

  const { data, isInitialLoading, error } = useAnalytics({
    params: {
      name,
      event: ['postedOffer'],
      sort: 'priceDesc',
      limit: 20
    }
  })

  const users = useMemo(() => {
    return data?.results
      ? data.results.reduce<string[]>((acc, record) => {
          const user = record.data?.buyer || record.data?.seller

          if (user && !acc.includes(user)) {
            acc.push(user)
          }

          return acc
        }, [])
      : []
  }, [data?.results])

  const usersWithThumbnails = useAddresses({
    params: {
      address: users,
      view: 'thumbnail'
    },
    options: {
      enabled: !!users && users.length > 0
    }
  })

  return {
    records: data?.results,
    isLoading: isInitialLoading,
    error,
    users,
    usersWithThumbnails
  }
}
