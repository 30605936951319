import { nfdRescindOffer, type RescindOfferRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type CancelSaleParams = {
  name: string
  body: RescindOfferRequestBody
}

export function usePostCancelSale(options: MutationOptions<CancelSaleParams> = {}) {
  return usePostTransaction<CancelSaleParams>({
    mutationFn: ({ name, body }) => nfdRescindOffer(name, body),
    ...options
  })
}
