import { NFDAnalyticRecords, nfdAnalytics, NfdAnalyticsParams } from 'api/api-client'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const getAnalytics = async (
  params: NfdAnalyticsParams,
  ssr?: boolean,
  options?: AxiosRequestConfig
): Promise<NFDAnalyticRecords> => {
  const { data } = await nfdAnalytics(params, {
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseAnalyticsArgs = {
  params: NfdAnalyticsParams
  options?: UseQueryOptions<NFDAnalyticRecords, AxiosError>
}

export default function useAnalytics({ params, options }: UseAnalyticsArgs) {
  const result = useQuery<NFDAnalyticRecords, AxiosError>(
    ['analytics', { ...params }],
    () => getAnalytics(params),
    { ...options }
  )

  return result
}
